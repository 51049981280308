import dashboard from './images/svg/dashboard.svg';
import logo from './images/svg/logo.svg';
import filter from './images/svg/filter.svg';
import close from './images/svg/close.svg';
import uplogo from './images/svg/uplogo.svg';
import email from './images/svg/email.svg';
import lock from './images/svg/lock.svg';
import blcklogo from './images/svg/blcklogo.svg';
import backarrow from './images/svg/backarrow.svg';
import sideprofile from './images/svg/side-profile.svg';
import sideadvertise from './images/svg/side-advrtisement.svg';
import sidesetting from './images/svg/side-setting.svg';
import activeSideProfile from './images/svg/active-side-profile.svg';
import  upload from './images/svg/upload.svg';
import  completed from './images/svg/completed.svg';
import tanimg from './images/svg/tanimg.svg';
import call  from './images/svg/call.svg';
import emailimg  from './images/svg/emailimg.svg'
import time from "./images/svg/time.svg";
import search from "./images/svg/search.svg";
import plus from "./images/svg/plus.svg";
import save from "./images/svg/save.svg";
import blueedit from "./images/svg/blue-edit.svg";
import closeblack from "./images/svg/close-black.svg"
import profilegrey from "./images/svg/profile-grey.svg"
import edit from "./images/svg/edit.svg"
// import changepassword from "./images/svg/cms (2).svg"
import closecircle from "./images/svg/close-circle.svg"
const Svg = {
    dashboard,
    // changepassword,
    profilegrey,
    closecircle,
    edit,
    closeblack,
    blueedit,
    save,
    plus,
    search,
    logo,
    filter,
    close,
    uplogo,
    email,
    lock,
    backarrow,
    blcklogo,
    upload,
    sideprofile,
    sideadvertise,
    sidesetting,
    activeSideProfile,
    completed,
    tanimg,
    call,
    emailimg,
time,
}

export default Svg;