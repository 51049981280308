import React, { useEffect, useState } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import "../category/Category.css";

const AddSubCategory = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({});
  const [error, setError] = useState({});
  const [categorySubmitted, setCategorySubmitted] = useState(false);
  const [image, setImage] = useState();
  const [categoryDataId, setCategoryDataId] = useState();
  const { data, handleSubCategoryList, parentId } = props;

  const handleClose = () => {
    if (!data) {
      setOpen(false);

      setEdit({ name: "", subCategoryImage: "", id: "", parentId: parentId });
      setCategorySubmitted(false);
      setImage("");
    } else {
      setOpen(false);
      setEdit({
        name: data?.name,
        subCategoryImage: data?.image,
        id: data?._id,
        parentId: parentId,
      });
    }
    setImage("");
    setError({});
  };

  useEffect(() => {
    const initialValues = {
      parentId: data ? data.parentId : "",
      name: data ? data.name : "",
      subCategoryImage: data ? data.image : "",
      id: data ? data._id : "",
    };
    setCategoryDataId(data?._id);
    setEdit(initialValues);
  }, [data]);

  const handleImageChange = (e) => {
    const file = e.currentTarget.files[0];
    const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
    if (file && !allowedFormats.includes(file.type)) {
      e.currentTarget.value = "";
      setEdit({
        ...edit,
        [e.target.name]: "",
      });
      setImage("");
      setError({ ...error, subCategoryImage: "SubCategory Image is required (Only JPG, JPEG, and PNG files are allowed)" });
      return;
    }
    setImage(file);
    setEdit({
      ...edit,
      [e.target.name]: file,
    });
    if (categorySubmitted) {
    const valid = PagesIndex.subCategoryValidation({
      ...edit,
      [e.target.name]: file,
    });
    setError(valid);
  };

  }
  const handleModelOpen = () => {
    setOpen(true); 
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    const inputValue = value.slice(0, 31);
    const capitalizedInputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setEdit({ ...edit, [name]: capitalizedInputValue });

    if (categorySubmitted) {
      const valid = PagesIndex.subCategoryValidation({ ...edit, [name]: capitalizedInputValue });
      setError(valid);
    }
  };

  const handleAddUpdateCategory = async () => {
    setCategorySubmitted(true);
    const formData = new FormData();
    formData.append("subCategoryImage", edit.subCategoryImage);
    formData.append("name", edit.name.trim());
    formData.append("parentId", edit.parentId);
    const valid = PagesIndex.subCategoryValidation(edit);
    setError(valid);
    const errorValid = Object.keys(valid).length;
    if (!errorValid) {
      if (!categoryDataId) {
        await PagesIndex.DataService.post(PagesIndex.Api.Admin.Add_SubCategoryData, formData)
          .then((res) => {
            if (res?.data?.status === 200) {
              PagesIndex.toast.success(res?.data.message);
              handleSubCategoryList();
              handleClose();
            }
          })
          .catch((error) => {
            PagesIndex.toast.error(error?.response?.data.message);
          });
      } else {
        formData.append("id", edit.id);
        await PagesIndex.DataService.put(PagesIndex.Api.Admin.Update_SubCategoryData, formData)
          .then((res) => {
            if (res.data.status === 200) {
              PagesIndex.toast.success(res.data.message);
              handleSubCategoryList();
              handleClose();
            }
          })
          .catch((error) => {
            PagesIndex.toast.error(error.response?.data?.message);
          });
      }
    }
  };

  return (
    <div>
      <Index.Button
        onClick={() => {
          handleModelOpen();
        }}
      >
        {props.data ? (
          <img
            alt="img"
            className="Add_Img"
            src={PagesIndex.Svg.blueedit}
          ></img>
        ) : (
          <span className="Add_Btn">Add SubCategory</span>
        )}
      </Index.Button>
      {open && (
        <>
          <Index.Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
          >
            <Index.Box
              sx={style}
              className="add-user-modal-inner-main modal-inner"
            >
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  {data ? "Update SubCategory" : "Add SubCategory"}
                </Index.Typography>
                <img
                  alt="IMG"
                  src={PagesIndex.Svg.closeblack}
                  className="modal-close-icon"
                  onClick={handleClose}
                />
              </Index.Box>

              <Index.Box className="input-box modal-input-box">
                <Index.FormHelperText className="form-lable form-image">
                  {categoryDataId ? (
                    image ? (
                      <>
                        {" "}
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Preview"
                          style={{ width: "80px", height: "50px" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        {edit.subCategoryImage ? (
                          <>
                            {" "}
                            <img
                              src={`${PagesIndex.imageURL}${edit.subCategoryImage}`}
                              alt="Preview"
                              style={{ width: "80px", height: "50px" }}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <img
                              src=""
                              alt=""
                              style={{ width: "80px", height: "50px" }}
                            />
                          </>
                        )}{" "}
                      </>
                    )
                  ) : (
                    image && (
                      <>
                        {" "}
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Preview"
                          style={{ width: "80px", height: "50px" }}
                        />
                      </>
                    )
                  )}
                </Index.FormHelperText>
                <Index.Typography className="form-lable">
                  {" "}
                  SubCategory Image*
                </Index.Typography>
                <Index.Box className="form-group">
                  <Index.TextField
                    type="file"
                    className="form-control field-arrow-control"
                    fullWidth
                    size="small"
                    accept="image/jpeg, image/jpg, image/png"
                    id="fullWidth"
                    placeholder=""
                    name="subCategoryImage"
                    onChange={handleImageChange}
                    inputProps={{ accept: "image/jpeg, image/jpg, image/png" }}
                  />
                  <span className="error-msg">
                    {handleImageChange && error.subCategoryImage}
                  </span>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box modal-input-box">
                <Index.FormHelperText className="form-lable">
                SubCategory Name*
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    type="text"
                    id="fullWidth"
                    size="small"
                    className="form-control field-arrow-control "
                    placeholder="SubCategory Name"
                    name="name"
                    value={edit.name}
                    onChange={handleCategoryChange}
                    error={Boolean(error.passingScore)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 9,
                        onWheel: (event) => event.target.blur(),
                      },
                    }}
                  />
                  <span className="error-msg">
                    {handleCategoryChange && error.name}
                  </span>
                </Index.Box>
              </Index.Box>

              <Index.Box className="modal-user-btn-flex">
                <Index.Box className="discard-btn-main border-btn-main">
                  <Index.Button
                    className="discard-user-btn border-btn"
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                    sx={{ color: "red" }}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="save-btn-main border-btn-main">
                  <Index.Button
                    className="save-user-btn border-btn"
                    onClick={handleAddUpdateCategory}
                  >
                    <img
                      alt="save"
                      src={PagesIndex.Svg.save}
                      className="user-save-icon"
                    ></img>
                    {data ? "Update" : "Save"}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </>
      )}
    </div>
  );
};

export default AddSubCategory;
