import React, { useEffect, useState } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import "./SubCategory.css";
import AddSubCategory from "./AddSubCategory";
import blankImage from "../../../../assets/images/png/blank-imge.png"
const SubCategory = () => {
  const [pageValue, setPageValue] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const categoryData = PagesIndex.useLocation();
  const subCategoryName = categoryData?.state?.row?.name;
  const parentId = categoryData?.state?.row?._id;
  const [subCategory, setSubCategoryData] = useState([]);

  const [searchTerm, setSearchTerm] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubCategoryList = async (event) => {
    try {
      setLoading(true);
      const res = await PagesIndex.DataService.get(
        `${PagesIndex.Api.Common.Get_ListSubCategory}?id=${parentId}&type=admin`
      );
      if (res.data.status === 200) {
        setSubCategoryData(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      PagesIndex.toast.error(error);
    }
  };

  useEffect(() => {
    handleSubCategoryList();
  }, []);

  useEffect(() => {
    setSearchTerm(subCategory);
  }, [subCategory]);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    const result =
      subCategory &&
      subCategory?.filter((item) => {
        return (
          new Date(item.createdAt)
            .toLocaleDateString("en-GB")
            .includes(searchQuery) ||
          (item?.name &&
            item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      });
    setSearchTerm(result);
    setPageValue(1);
  };

  const pageChange = (e, value) => {
    setPageValue(value);
  };

  const HandleActiveDeactiveSubCategory = async (categoryId, status) => {
    try {
      const urlEncodedData = new URLSearchParams();

      urlEncodedData.append("id", categoryId);
      urlEncodedData.append("status", status);

      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.ActiveInactive_SubCategoryStatus,
        urlEncodedData
      );

  
      if (res.data.status === 200) {
        PagesIndex.toast.success(res.data.message);
      }
      handleSubCategoryList();
    } catch (error) {
      PagesIndex.toast.error(error);
    }
  };

  const rowsPerPage = 10;
  useEffect(() => {
    const countData = Math.ceil(searchTerm?.length / rowsPerPage);
    setPageCount(countData);
    const indexOfLastRow = pageValue * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const modifiedRows =
      searchTerm && searchTerm?.slice(indexOfFirstRow, indexOfLastRow);

    setPageData(modifiedRows);
    if (modifiedRows?.length === 0 && pageValue > 1) {
      setPageValue(pageValue - 1);
    }
  }, [pageValue, searchTerm]);

  return (
    <>
      <Index.Box className="dashboard-content ">
        <Index.Box className="user-list-flex">
          <Index.Box>
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.Typography
                  className="admin-page-title user-list-page-title heading-text"
                  component="h2"
                  variant="h2"
                >
                  {subCategoryName} Category List
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  onChange={handleSearchChange}
                  id="fullWidth"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Search SubCategory"
                />

                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>

            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button className="adduser-btn btn-primary">
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  <AddSubCategory
                    handleSubCategoryList={handleSubCategoryList}
                    parentId={parentId}
                  />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          {/* {loading ? (
            <PagesIndex.UgoLoader color="#233862" loading={loading} />
          ) : ( */}
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box
                      sx={{ width: "100%", overflow: "hidden" }}
                      className="subCategorylist-table-main page-table-main"
                    >
                      <Index.TableContainer
                        sx={{ maxHeight: "350px" }}
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No.
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Image
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                SubCategory Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
      
                          <Index.TableBody className="table-body">
                          {loading ? (
                            <>
                              {/* <Index.TableRow>
                                <Index.TableCell
                                  colSpan={10}
                                  align="center"
                                  className="no-data-cell"
                                >
                                  <PagesIndex.UgoLoader
                                    color="#233862"
                                    loading={loading}
                                  />
                                </Index.TableCell>
                              </Index.TableRow> */}
                            </>
                          ):(<>
                            {searchTerm?.length > 0 ? (
                              pageData &&
                              pageData?.map((row, ind) => (
                                <Index.TableRow
                                  key={row?._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {(pageValue - 1) * rowsPerPage + ind + 1}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    <Index.Box className="userlist-data">
                                      <img
                                        alt="img"
                                        src={row?.image ? `${PagesIndex.imageURL}${row.image}` : `${blankImage}`}
                                        // src={`${PagesIndex.imageURL}${row?.image}`}
                                        className="categorylist-img"
                                      ></img>{" "}
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.name}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      {/* <Index.Button
                                        className="action-btn"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        <Index.AddIcon size="small" />
                                      </Index.Button> */}
                                      <AddSubCategory
                                        data={row}
                                        handleSubCategoryList={
                                          handleSubCategoryList
                                        }
                                        parentId={parentId}
                                      />
                                      <Index.Switch
                                        checked={row?.isActive === true}
                                        onClick={() =>
                                          HandleActiveDeactiveSubCategory(
                                            row?._id,
                                            row?.isActive === true ? false : true
                                          )
                                        }
                                      />
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  colSpan={10}
                                  align="center"
                                  className="no-data-cell"
                                >
                                  No subCategory found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                            </>)}
                          </Index.TableBody>
       
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                    {searchTerm && searchTerm?.length > 0 ? (
                      <Index.Box className="pagination-main">
                        <Index.Pagination
                          count={pageCount}
                          page={pageValue}
                          onChange={pageChange}
                          variant="outlined"
                          shape="rounded"
                          className="pagination"
                        />
                      </Index.Box>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          {/* )} */}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SubCategory;
