import logo from "./logo.svg";
import "./App.css";
import "./assets/style/global.css";
import "./assets/style/global.responsive.css";
import Routes from "./routes/Routes";


function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
