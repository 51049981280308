import React, { useEffect, useState } from "react";
import Index from "../Index";
import "./SubscriptionPlan.css";
import PagesIndex from "../PagesIndex";

const SubscriptionPlan = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const submitForm = async (values) => {
    try {
      setBtnLoading(true)
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.Update_CoupanAmount,
        {
          newAmount: values?.currentVoucherRate,
          vatAmount: parseFloat(values?.vatAmount),
        }
      );
      if (res?.data.status === 200) {
        getAmount();
        PagesIndex.toast.success(res?.data?.message,{toastId: "customId"});
      }
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
    }
  };

  const getAmount = async () => {
    setLoading(true);
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Common.Get_CoupanPrice
      );
      if (res?.data.status === 200) {
        setData(res?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // Handle error
    }
  };

  useEffect(() => {
    getAmount();
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content ">
        <Index.Box className="user-list-flex">
          <Index.Box>
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.Typography
                  className="admin-page-title user-list-page-title"
                  component="h2"
                  variant="h2"
                >
                  Voucher Cost
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {!loading ? (
          <PagesIndex.Formik
            validationSchema={PagesIndex.SubscriptionCostSchema}
            initialValues={{
              currentVoucherRate: data?.currentVoucherRate,
              vatAmount: data?.vatAmount,
            }}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="container-box">
                  <Index.Box className="change-space">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="change-input-label "
                    >
                      Coupan Cost*
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        type="number"
                        name="currentVoucherRate"
                        onChange={(e) => {
                          const inputValue = e.target.value.replace(/\D/g, "");
                          setFieldValue(
                            "currentVoucherRate",
                            inputValue.slice(0, 5)
                          );
                        }}
                        className="admin-input-design input-placeholder"
                        value={values?.currentVoucherRate}
                        onBlur={handleBlur}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                      {errors.currentVoucherRate &&
                      touched.currentVoucherRate ? (
                        <Index.Typography className="error-msg">
                          {errors.currentVoucherRate}
                        </Index.Typography>
                      ) : null}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="change-space">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="change-input-label "
                    >
                      Vat Percentage*
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        name="vatAmount"
                        type="number"
                        onChange={(e) => {
                          const inputValue = e.target.value.replace(/\D/g, "");
                          setFieldValue("vatAmount", inputValue.slice(0, 5));
                        }}
                        // onChange={(e) => {
                        //   const inputValue = e.target.value;
                        //   if (/^0+$/.test(inputValue)) {
                        //     e.target.value = "";
                        //   }
                        //   if (/^\d{0,5}$/.test(inputValue)) {
                        //     handleChange(e);
                        //   }
                        // }}

                        className="admin-input-design input-placeholder"
                        value={values.vatAmount}
                        onBlur={handleBlur}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                      {errors.vatAmount && touched.vatAmount ? (
                        <Index.Typography className="error-msg">
                          {errors.vatAmount}
                        </Index.Typography>
                      ) : null}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="common-btn small-btn-modal position">
                    <Index.Button variant="contained" type="submit"
                        disabled={btnLoading}
                        style={{ color: "white" }}
                        startIcon={btnLoading ? <PagesIndex.UgoButtonLoader /> : null}
                        >
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        ) : null}
      </Index.Box>
    </>
  );
};

export default SubscriptionPlan;
