
import arrow from './images/png/arrow.png';
import Profile from './images/png/profile.png';
import sale from './images/png/sale.png';
import pagenotfound from './images/png/pagenotfound.png'

const Png = {
    
      arrow,
      Profile,
      sale,
      pagenotfound
      
}

export default Png;