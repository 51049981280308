const Api = {
  Admin: {
    Admin_Login: "admin/login",
    Admin_ForgotPassword: "admin/forgotPassword",
    Admin_VerifyOtp: "admin/verifyOtp",
    Admin_ResetPassword: "admin/resetPassword",
    Admin_ChangePassword: "/admin/changePassword",
    Add_CategoryData: "admin/category",
    Update_CategoryData: "admin/category",
    ActiveInactive_CategoryStatus: "admin/category",
    Add_SubCategoryData: "admin/subCategory",
    Update_SubCategoryData: "admin/subCategory",
    ActiveInactive_SubCategoryStatus: "admin/subCategory",
    Admin_ProfileData: "admin/viewProfile",
    Admin_ProfileUpdate: "admin/updateProfile",
    Update_TermsCondition: "admin/editCms?type=terms",
    Update_PrivacyPolicy: "admin/editCms?type=privacy",
    Get_UserList: "admin/userList",
    Get_BusinessList: "admin/businessList",
    Delete_User: "admin/user",
    Delete_Business: "admin/business",
    ActiveInactive_UserStatus: "admin/user/status",
    ActiveInactive_BusinessStatus: "admin/business/status",
    Get_DashboardData:"admin/dashboard",
    Get_AllTransaction:"admin/all/payments",
    Update_CoupanAmount:"admin/update-coupan-amount",
    Get_CoupanAmount:"admin/update-coupan-amount",
    Add_Update_Distance_Cost:"admin/add-update-distance-cost",
    getAllDistanceCost:"admin/get-all-distance-cost"
  },
  Common: {
    Get_ListAllCategory: "common/list/category?type=admin",
    Get_ListSubCategory: "common/list/subCategoryWithCategory",
    Get_TermsCondition: "common/getCMS?type=terms",
    Get_PrivacyPolicy: "common/getCMS?type=privacy",
    Get_CoupanPrice:"common/coupan-price"
  },
};
export { Api };
